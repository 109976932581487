<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-img :src="require('@/assets/logo.png')" class="center mb-4" height="60" contain></v-img>
        <v-card outlined class="pa-6 ma-auto" max-width="360px" elevation="4">
          <h1 class="text-center">Forgot</h1>
          <form>
            <v-text-field v-model="username" :error-messages="usernameErrors" append-icon="mdi-account" label="Username" required @input="$v.username.$touch()" @blur="$v.username.$touch()"></v-text-field>
            
            <v-text-field label="New Password" v-model="newPassword" :error-messages="newPasswordErrors" :type="show1 ? 'text' : 'password'" required @input="$v.newPassword.$touch()" @blur="$v.newPassword.$touch()" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"></v-text-field>
            <v-text-field label="Confirm Password" v-model="confirmPassword" :error-messages="confirmPasswordErrors" :type="show2 ? 'text' : 'password'" required @input="$v.confirmPassword.$touch()" @blur="$v.confirmPassword.$touch()" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2"></v-text-field>
            
            <v-text-field v-model="verificationCode" :error-messages="verificationCodeErrors" label="Verification Code" required @input="$v.verificationCode.$touch()" @blur="$v.verificationCode.$touch()"></v-text-field>
            <a href="javascript:;" @click="getVerificationCode">Get Verification Code</a>

            <v-btn class="mr-4 my-2 mt-6" @click="changePassword" color="primary" block> Change Password </v-btn>
            <v-btn class="mr-4 my-2" block link :to="{ name: 'Login' }"> Back to Login </v-btn>
          </form>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    username: { required },
    verificationCode: { required },
    newPassword: { required },
    confirmPassword: { required },
  },

  data: () => ({
    username: "",
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
    show1: null,
    show2: null,
    overlay: false,
  }),

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Username is required");
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      !this.$v.newPassword.required && errors.push("New Password is required");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required && errors.push("New Password is required");
      return errors;
    },
    verificationCodeErrors() {
      const errors = [];
      if (!this.$v.verificationCode.$dirty) return errors;
      !this.$v.verificationCode.required && errors.push("Verification Code is required");
      return errors;
    },
  },

  methods: {

    changePassword() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.showLoadingOverlay(true);

      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("new_password", this.newPassword);
      formData.append("conf_password", this.confirmPassword);
      formData.append("verification_code", this.verificationCode);

      this.$axios.post("auth/forgot/changepassword", formData).then((res) => {
        this.showLoadingOverlay(false);
        if (res.data.status == "success") {
          this.showAlert(res.data.status, res.data.message);
          alert(res.data.message);
          this.$router.push("/login");
          //
        } else {
          this.showAlert(res.data.status, res.data.message);
        }
      })
      .catch((error) => {
        this.showLoadingOverlay(false);
        this.axiosErrorHandler(error);
      });
    },
    getVerificationCode(){
      if(this.$v.username.$touch()){
        //
      }

      if(this.username === ""){
        this.showAlert("error", "Username is required!");
        this.$v.username.focus()
        return false;
      }

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("username", this.username);

      this.$axios.post("auth/forgot/request", formData).then((res) => {
        this.showLoadingOverlay(false);
        if (res.data.status == "success") {
          this.showAlert(res.data.status, res.data.message);
          //
        } else {
          this.showAlert(res.data.status, res.data.message);
        }
      })
      .catch((error) => {
        this.showLoadingOverlay(false);
        this.axiosErrorHandler(error);
      });
    },
    clear() {
      this.$v.$reset();
      this.username = "";
      this.password = "";
    },
  },
};
</script>